import {useLoaderData} from '@remix-run/react';
import type {
  ActionFunctionArgs,
  LinksFunction,
} from '@remix-run/server-runtime';

import PageLayout from '@/components/shared/Page/PageLayout';
import {EN_BLOG_PATH} from '@/utils/blog/partnersHelpers';
import type {IndexSectionArticles} from '@/utils/server/blog/indexPageDefinitions/types.server';
import handleWaitlistFormSubmission from '@/utils/server/action/waitlist/handleWaitlistFormSubmission.server';
import {blogIndexLoader} from '@/utils/server/loader/blogIndexLoader.server';
import blogStylesheet from '@/stylesheets/blog/blog.css?url';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import {BlogConversion} from '@/components/blog/BlogConversion/BlogConversion';
import {useTranslations} from '@/hooks/useTranslations';
import {BlogSubNav} from '@/components/pages/blog/BlogSubNav/BlogSubNav';
import {partnersPath} from '@/hooks/navigation/utils';
import {useSiteData} from '@/hooks/useSiteData';
import {PartnersFooter} from '@/pages/shopify.com/($locale)/partners/blog/components/PartnersFooter/PartnersFooter';
import {useBlogPageTitle} from '@/hooks/blog/useBlogPageTitle';
import {PartnersHeroConversion} from '@/components/pages/partners/blog/PartnersHeroConversion/PartnersHeroConversion';
import Hero from '@/components/pages/blog/Hero/Hero';
import ArticleCarousel from '@/components/pages/blog/ArticleCarousel/ArticleCarousel';

export {partnersHandle as handle} from '@/pages/shopify.com/($locale)/partners/blog/handle';

export const loader = blogIndexLoader;

export function action({request}: ActionFunctionArgs) {
  return handleWaitlistFormSubmission(request);
}

export const links: LinksFunction = () => {
  return [{rel: 'stylesheet', href: blogStylesheet}];
};

export default function PartnersBlog() {
  const {t} = useTranslations();
  const {site} = useSiteData();
  const {
    heroArticles = [],

    category0Articles = [],
    category1Articles = [],

    localizedSettings,
  } = useLoaderData<IndexSectionArticles>() ?? {};

  const pageTitle = useBlogPageTitle(t('htmlHead.pageTitle'));
  const heroConversionCta = heroArticles[0] && (
    <PartnersHeroConversion componentName="home-CTA-block-email" />
  );

  return (
    <PageLayout
      title={pageTitle}
      header={(props) => (
        <BlogSubNav
          {...props}
          enBlogPath={EN_BLOG_PATH}
          blogPageType={BLOG_PAGE_TYPE.PARTNERS}
        />
      )}
      footer={<PartnersFooter />}
      isPureTitle
    >
      <div className="blog relative bg-white pt-36 text-black">
        <Hero
          featuredArticle={heroArticles[0]}
          secondaryArticles={heroArticles.slice(-2)}
          enBlogPath={EN_BLOG_PATH}
          conversionCta={heroConversionCta}
        />
        {category0Articles.length > 0 && (
          <ArticleCarousel
            className="bg-[#F6F6F1]"
            heading={localizedSettings.category0.heading}
            primaryArticles={category0Articles.slice(0, 4)}
            enBlogPath={EN_BLOG_PATH}
            link={{
              text: t('carouselSeeAllLinkText'),
              url: localizedSettings.category0.url,
            }}
            carouselName={localizedSettings.category0.url.split('/').pop()!}
            headingTag="h5"
            preferredBlogCardTag={
              localizedSettings.category0.preferredBlogCardTag ||
              localizedSettings.category0.heading
            }
          />
        )}

        {category1Articles.length > 0 && (
          <ArticleCarousel
            heading={localizedSettings.category1.heading}
            primaryArticles={category1Articles}
            enBlogPath={EN_BLOG_PATH}
            link={{
              text: t('carouselSeeAllLinkText'),
              url: localizedSettings.category1.url,
            }}
            carouselName={localizedSettings.category1.url.split('/').pop()!}
            headingTag="h5"
            preferredBlogCardTag={
              localizedSettings.category1.preferredBlogCardTag ||
              localizedSettings.category1.heading
            }
          />
        )}
        <BlogConversion
          heading={t('sellAnywhere.headingHtml')}
          buttonHref={partnersPath(site)}
          buttonText={t('sellAnywhere.buttonText')}
          mode="partners"
          сomponentName="cta-footer-banner"
        />
      </div>
    </PageLayout>
  );
}
