import cn from 'classnames';

import {useTranslations} from '@/hooks/useTranslations';
import Conversion from '@/components/pages/blog/SubscribeForm/Conversion/Conversion';
import {PARTNERS_SIGNUP_URL} from '@/components/pages/partners/blog/constants';

import {conversionHeadingStyles, conversionButtonStyles} from './style';

export const PartnersHeroConversion = ({
  componentName,
}: {
  componentName?: string;
}) => {
  const {t} = useTranslations();

  const buttons = [
    {
      text: t('conversionCta.buttonText'),
      href: PARTNERS_SIGNUP_URL,
    },
  ];
  const headingHtml = (
    <>
      <div>{t('conversionCta.headingFirstRow')}</div>
      <div>{t('conversionCta.subheadSecondRow')}</div>
    </>
  );

  return (
    <div
      className={cn(
        'bg-partners-conversionBg bg-partners-hero-converion bg-no-repeat py-9',
        'bg-[calc(100%+80px)_calc(100%+20px)] sm:bg-right-bottom bg-full',
      )}
      data-component-name={componentName}
    >
      <Conversion
        buttons={buttons}
        headingHtml={headingHtml}
        headingClassName={conversionHeadingStyles}
        buttonsClassName={conversionButtonStyles}
        className="mx-6 tablet:mx-9"
        mode="dark"
        center={false}
      />
    </div>
  );
};
